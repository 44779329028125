<template>
  <article class="p-4 bg-gray-50 pt-6 font-noto text-lg">
    <h1 class="bg-gray-100 text-center text-3xl font-semibold font-roboto">
      <span class="text-blue-700">C</span>ontact Us
    </h1>
    <section class="about">
      <p class="">
        At Hamsuper, we are committed to addressing any possible issues or
        questions you may have concerning our services and therefore we will be
        glad to hear from you and to be of help.
      </p>
    </section>
    <section>
      <h5 class="font-bold p-2">Call Us:</h5>
      <p>
        0814 687 0699
      </p>
    </section>
    <section class="mt-6">
      <h5 class="font-bold p-2">Email:</h5>
      <p>
        info@hamsuper.com
      </p>
    </section>
    <section class="mt-6">
      <h5 class="font-bold p-2">Address:</h5>
      <p>
        2ND AVENUE, BLOCK 59, HOUSE 10, NAVY TOWN, OJO LAGOS
      </p>
    </section>
  </article>
</template>
<style lang="scss" scoped>
.about {
  margin-top: 2rem;
  p {
    margin-bottom: 1.5rem;
    text-indent: 2%;
  }
}
</style>
