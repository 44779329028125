<template>
  <article class="p-4 rounded-md bg-blue-900 border-2 border-blue-100">
    <img
      class="w-full sm:h-64 mx-auto"
      viewBox="0 0 1177 598.5"
      src="../assets/images/galaxy_s21.png"
      alt=""
    />
  </article>
</template>
<script>
export default {
  name: "categoryIcons",
};
</script>
