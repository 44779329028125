<template>
  <div id="pd">
    <span>. </span>
  <div>
    <span>Loading... </span>
  </div>
</div>
</template>

<script>
export default {
  name: "Loaoding",
  props: {
    msg: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div#pd{
  width: 98%;
  border: 1% solid rgb(169, 169, 247);
  position: relative;
  margin-bottom: .8em;
  text-align: left;
  div{
    width:1px;
    background-color: rgb(169, 169, 247);
    padding: 5px;
    animation-name: loading;
    animation-duration: 9s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    position:absolute;
    top:0px;
    text-align: center;
  }
}
@keyframes loading{
  0%{
    width: 0%;
    font-size: 1em;
  }
  20%{
    width: 20%;
    text-align: left;;
    font-size: .8em;
    background-color: rgb(226, 250, 240);
  }
  40%{
    width: 40%;
    text-align: center;
    font-size: 1em;
    background-color: rgb(190, 248, 224);
  }
  60%{
    width: 60%;
    text-align: right;;
    font-size: 1em;
    background-color: rgb(226, 250, 240);
  }
  80%{
    width: 80%;
    text-align: center;
    font-size: .8em;
    background-color: rgb(62, 255, 175);
  }
  100%{
    width: 100%;
    text-align: right;
    font-size: 1em;
    background-color: rgb(0, 252, 147);
  }
}
</style>
